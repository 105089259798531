import React from 'react';

function App() {
  const redirectToTelegram = () => {
    window.location.href = 'https://t.me/milly_chatbot/11';
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 font-sans">
      <div className="w-full lg:w-1/3 h-56 sm:h-64 md:h-72 lg:h-auto bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/top2.png)`}}></div>
      <div className="w-full lg:w-2/3 flex items-center justify-center p-2 sm:p-4 lg:p-6">
        <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg rounded-3xl p-3 sm:p-4 lg:p-6 shadow-2xl w-full max-w-4xl">
          <header className="text-center mb-2 sm:mb-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold text-indigo-900 leading-tight">
              Милли - твой виртуальный психолог в Telegram 🌟
            </h1>
          </header>

          <main className="text-indigo-800 text-sm sm:text-base md:text-lg lg:text-xl font-medium">
            <p className="mb-2 sm:mb-4 text-lg sm:text-xl md:text-2xl lg:text-3xl">
              Нужен друг, который всегда рядом? Милли — твой виртуальный психолог в Telegram!
            </p>

            <ul className="mb-2 sm:mb-4 list-none space-y-1 sm:space-y-2">
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">❓</span> Чувствуешь тревогу, но не знаешь, как справиться?</li>
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">❓</span> Трудно разобраться в эмоциях?</li>
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">❓</span> Стесняешься поделиться с близкими?</li>
            </ul>

            <p className="mb-2 sm:mb-3 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-indigo-900">Почему выбрать Милли?</p>
            <ul className="mb-2 sm:mb-4 list-none space-y-1 sm:space-y-2">
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">🍃</span> Комфорт без осуждений: забудь о страхе быть непонятым.</li>
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">📚</span> Психологическая мудрость: знания и опыт психотерапии облегчат тебе жизнь.</li>
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">🕒</span> Круглосуточная поддержка: Милли всегда на связи, готова помочь в любое время.</li>
              <li className="flex items-center"><span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mr-2">🔐</span> Твоя конфиденциальность — наш приоритет: полная анонимность и безопасность данных.</li>
            </ul>

            <p className="mb-3 sm:mb-4 md:mb-6 text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold text-center">Пора действовать! Начни свой путь к гармонии сегодня.</p>

            <div className="text-center">
              <button
                onClick={redirectToTelegram}
                className="bg-gradient-to-r from-pink-500 to-purple-500 text-white px-4 sm:px-6 md:px-8 lg:px-10 py-2 sm:py-3 rounded-full text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold hover:from-pink-600 hover:to-purple-600 transition duration-300 shadow-lg transform hover:scale-105"
              >
                👉 НАЧАТЬ СЕЙЧАС
              </button>
            </div>
          </main>

          <footer className="mt-6 sm:mt-8 md:mt-10 lg:mt-12 text-center">
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-indigo-800 font-medium">
              С Милли ты никогда не останешься один на один с трудностями 💖
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
